import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TransactionLog } from '../models/transaction-log.model';

@Injectable({
  providedIn: 'root'
})
export class AIIntegrationService {

  private aiAppIntegrationUrl = 'api/v1/aiintegration/app';
  private aiIntegrationUrl = 'api/v1/aiintegration';

  constructor(private http: HttpClient) {}

  getOpenedTransactionLog(cycleId: number, canisterLocation: string): Observable<TransactionLog> {
    return this.http
      .get<TransactionLog>(`${this.aiIntegrationUrl}/log/${cycleId}/${canisterLocation}`)
      .pipe(catchError(this.handleError));
  }

  insertTransactionLog(transactionId: string, cycleId: number, userId: string, canisterLocation: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .post<any>(`${this.aiAppIntegrationUrl}/${transactionId}/${cycleId}/${userId}/${canisterLocation}`, options)
      .pipe(catchError(this.handleError));
  }

  handleError = (error: HttpErrorResponse) => {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.message}`);
      // if (error.status === 404) {
      //     this.router.navigateByUrl('/404', { skipLocationChange: true });
      // }
      console.error(error);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
