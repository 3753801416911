import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CycleCountCanisterPointedItemDialogData } from 'src/app/models/cycle-count-canister-pointed-item-dialog-data';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-cycle-count-canister-pointed-item',
  templateUrl: './cycle-count-canister-pointed-item.component.html',
  styleUrls: ['./cycle-count-canister-pointed-item.component.scss']
})
export class CycleCountCanisterPointedItemComponent implements OnInit {

  @ViewChild('form', { static: true }) form: NgForm;

  error = false;
  quantity: number;

  constructor(
    private dataService: DataService,
    public translateService: TranslateService,
    public dialogRef: MatDialogRef<CycleCountCanisterPointedItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CycleCountCanisterPointedItemDialogData,
    public dialog: MatDialog) {
    dialogRef.disableClose = true;
    this.quantity = data.item.countedQuantity;
  }

  ngOnInit() {}

  onSubmit(): void {
    this.error = false;
    if (this.validate()) {
      this.data.item.countedQuantity = this.quantity;
      this.dataService.updateCanisterPointedItem(this.data.item).subscribe((response) => {
        if (response) {
          this.data.item = response;
          this.dialogRef.close(true);
        }
      }, () => {
        this.error = true;
      });
    }
  }

  validate(): boolean {
    Object.keys(this.form.controls).forEach(field => this.form.controls[field].markAsTouched());
    const control = this.form.controls['txtQuantity'];
    if (!control.errors && control.value < 0) {
      control.setErrors({ 'min': true });
    }
    return this.form.valid;
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
