import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BullSearchDialogComponent } from 'src/app/bull-search-dialog/bull-search-dialog.component';
import { LocationSearchDialogComponent } from 'src/app/location-search-dialog/location-search-dialog.component';
import { Bull } from 'src/app/models/bull.model';
import { CycleCountCanisterNotPointedItemDialogData } from 'src/app/models/cycle-count-canister-not-pointed-item-dialog-data';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-cycle-count-canister-not-pointed-item',
  templateUrl: './cycle-count-canister-not-pointed-item.component.html',
  styleUrls: ['./cycle-count-canister-not-pointed-item.component.scss']
})
export class CycleCountCanisterNotPointedItemComponent implements OnInit {

  @ViewChild('form', { static: true }) form: NgForm;

  branchError = false;
  error = false;  
  quantity: number;
  imageData: string;
  thumbSrc: string;

  constructor(
    private dataService: DataService,
    public translateService: TranslateService,
    public dialogRef: MatDialogRef<CycleCountCanisterNotPointedItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CycleCountCanisterNotPointedItemDialogData,
    public dialog: MatDialog) {
    dialogRef.disableClose = true;
    if (data && data.item) {
      if (!data.item.bull) {
        data.item.bull = new Bull();
      }
      this.quantity = data.item.countedQuantity;
    }
  }

  ngOnInit() {}  

  onSubmit() {
    this.branchError = false;
    this.data.item.branchPlant = '';
    if (this.validate()) {
      this.data.item.countedQuantity = this.quantity;
      this.getBranch();
    }
  }

  getBranch() {
    this.dataService
      .getBranch(this.data.item.bull.id, `${this.data.item.location}${this.data.item.locationSuffix}`, this.data.item.lot)
      .subscribe((response) => {
        if (response) {
          this.data.item.branchPlant = response;
          if (this.data.item.id) {
            this.updateCanisterNotPointedItem();
          } else {
            this.addCanisterNotPointedItem();
          }
        } else {
          this.branchError = true;
        }
    }, error => {
      console.log(error);
    });
  }

  updateCanisterNotPointedItem(): void {
    this.dataService.updateCanisterNotPointedItem(this.data.item).subscribe((response) => {
      if (response) {
        this.data.item = response;
      }
      this.dialogRef.close(true);
    }, error => {
      this.error = true;
    });
  }

  addCanisterNotPointedItem(): void {
    this.dataService.addCanisterNotPointedItem(this.data.item).subscribe((response) => {
      if (response) {
        this.data.item = response;
      }
      this.dialogRef.close(true);
    }, error => {
      this.error = true;
    });
  }

  validate(): boolean {
    Object.keys(this.form.controls).forEach(field => this.form.controls[field].markAsTouched());
    const control = this.form.controls['txtQuantity'];
    if (!control.errors && control.value < 0) {
      control.setErrors({ 'min': true });
    }
    return this.form.valid;
  }

  openBullSearchDialog() {
    const dialogRef = this.dialog.open(BullSearchDialogComponent, {
      width: '500px',
      data: null
    });

    dialogRef.afterClosed().subscribe((result: Bull) => {
      if (result) {
        this.data.item.bull.id = result.id;
        this.data.item.bull.code = result.code;
        this.data.item.bull.name = result.name;
        this.data.item.branchPlant = '';
      }
    });
  }

  // openLocationSearchDialog() {
  //   const dialogRef = this.dialog.open(LocationSearchDialogComponent, {
  //     width: '500px',
  //     data: this.data.item.cycleNumber
  //   });

  //   dialogRef.afterClosed().subscribe((result: string) => {
  //     if (result) {
  //       this.data.item.location = result;
  //     }
  //   })
  // }  

  cancel(): void {
    this.dialogRef.close();
  }
}
