import { DataService } from './../../services/data.service';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { PointedItemDialogData } from 'src/app/models/pointed-item-dialog-data';
import { TranslateService } from '@ngx-translate/core';
import { ImageViewerComponent } from 'src/app/image-viewer/image-viewer.component';
import { PhotoDialogV2Component } from 'src/app/photo-dialog-v2/photo-dialog-v2.component';

@Component({
  selector: 'app-cycle-count-pointed-item',
  templateUrl: './cycle-count-pointed-item.component.html',
  styleUrls: ['./cycle-count-pointed-item.component.scss']
})
export class CycleCountPointedItemComponent implements OnInit {

  @ViewChild('form', { static: true }) form: NgForm;
  @ViewChild('imageViewer', { static: false }) imageViewer: ImageViewerComponent;

  error = false;
  quantity: number;
  imageData: string | ArrayBuffer;
  thumbSrc: string | ArrayBuffer;
  @ViewChild('inputCamera') inputCamera: ElementRef;

  constructor(
    private _dataService: DataService,
    public translateService: TranslateService,
    public dialogRef: MatDialogRef<CycleCountPointedItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PointedItemDialogData,
    public dialog: MatDialog) {
    dialogRef.disableClose = true;
    this.quantity = data.isRecount ? data.item.recountQuantity : data.item.countQuantity;
  }

  ngOnInit() {
    this.setThumbSrc();
  }

  onSubmit(): void {
    this.error = false;
    if (this.validate()) {

      if (this.data.isRecount) {
        this.data.item.recountQuantity = this.quantity;
      } else {
        this.data.item.countQuantity = this.quantity;
      }

      this._dataService.updatePointedItem(this.data.item).subscribe((response) => {
        if (response) {
          this.data.item = response;
          this.dialogRef.close(true);
        }
      }, error => {
        this.error = true;
      });
    }
  }

  validate(): boolean {
    Object.keys(this.form.controls).forEach(field => this.form.controls[field].markAsTouched());
    const control = this.form.controls['txtQuantity'];
    if (!control.errors && control.value < 0) {
      control.setErrors({ 'min': true });
    }
    return this.form.valid;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  openCamera() {
    this.inputCamera.nativeElement.click();
  }

  // openPhotoDialog(): void {

  //   const dialogRef = this.dialog.open(PhotoDialogV2Component, {
  //     autoFocus: false,
  //     width: '548px',
  //     height: '680px'
  //   });

  //   dialogRef.afterClosed().subscribe((result: string | boolean) => {
  //     if (result) {
  //       this.imageData = result as string;
  //       this.data.item.imageBase64 = result as string;
  //       if (this.data.isRecount) {
  //         this.data.item.recountImageUrl = null;
  //       } else {
  //         this.data.item.countImageUrl = null;
  //       }
  //       this.setThumbSrc();
  //     }
  //   });
  // }

  deleteImage(): void {
    this.imageData = null;
    this.data.item.imageBase64 = null;
    if (this.data.isRecount) {
      this.data.item.recountImageUrl = null;
    } else {
      this.data.item.countImageUrl = null;
    }
  }

  setThumbSrc(): void {
    if (this.data.isRecount) {
      this.thumbSrc = this.data.item.recountImageUrl ? `${this.data.item.recountImageUrl}&${new Date().getTime()}` : this.imageData;      
    } else {
      this.thumbSrc = this.data.item.countImageUrl ? `${this.data.item.countImageUrl}&${new Date().getTime()}` : this.imageData;
    }    
  }

  viewImage(): void {
    this.imageViewer.show();
  }

  imageChange(event) {
    console.log(event.target.files[0]);
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        this.imageData = reader.result;
        this.data.item.imageBase64 = reader.result as string;
        this.setThumbSrc();
      } 
      reader.readAsDataURL(file);      
    }
  }

}
