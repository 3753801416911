import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { CycleCountCanisterNotPointedItem } from 'src/app/models/cycle-count-canister-not-pointed-item.model';
import { CycleCountCanisterPointedItemDialogData } from 'src/app/models/cycle-count-canister-pointed-item-dialog-data';
import { CycleCountCanisterPointedItem } from 'src/app/models/cycle-count-canister-pointed-item.model';
import { CycleCountListItem } from 'src/app/models/cycle-count-list-item.model';
import { DataService } from 'src/app/services/data.service';
import { LoaderService } from 'src/app/services/loader.service';
import { CycleCountCanisterPointedItemComponent } from '../cycle-count-canister-pointed-item/cycle-count-canister-pointed-item.component';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { CycleCountCanisterNotPointedItemDialogData } from 'src/app/models/cycle-count-canister-not-pointed-item-dialog-data';
import { CycleCountCanisterNotPointedItemComponent } from '../cycle-count-canister-not-pointed-item/cycle-count-canister-not-pointed-item.component';

@Component({
  selector: 'app-cycle-count-canisters-items',
  templateUrl: './cycle-count-canisters-items.component.html',
  styleUrls: ['./cycle-count-canisters-items.component.scss']
})
export class CycleCountCanistersDetailsComponent implements OnInit {

  pointedItemsDataSource: MatTableDataSource<CycleCountCanisterPointedItem>;
  pointedItemsColumns: string[] = ['itemNumber', 'description', 'location', 'lot', 'countedQuantity'];
  notPointedItemsDataSource: MatTableDataSource<CycleCountCanisterNotPointedItem>;
  notPointedItemsColumns: string[] = ['itemNumber', 'description', 'location', 'lot', 'countedQuantity'];

  constructor(
    private _dataService: DataService,
    public translateService: TranslateService,
    public loaderService: LoaderService,
    public dialogRef: MatDialogRef<CycleCountCanistersDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CycleCountListItem,
    public dialog: MatDialog) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.data) {
      setTimeout(() => {
        this.loadData();
      }, 100);
    }
  }

  loadData(): void {
    this._dataService.getCanistersItems(this.data.id, this.data.location).subscribe((response) => {
      if (response) {
        this.pointedItemsDataSource = new MatTableDataSource(response.pointedItems);
        this.notPointedItemsDataSource = new MatTableDataSource(response.notPointedItems);
      }
    });
  }

  editPointedItem(item: CycleCountCanisterPointedItem) {
    const cardData = new CycleCountCanisterPointedItemDialogData(Object.assign({}, item));
    const dialogRef = this.dialog.open(CycleCountCanisterPointedItemComponent, {
      data: cardData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        setTimeout(() => {
          item.countedQuantity = cardData.item.countedQuantity;
          item.branchPlant = cardData.item.branchPlant;
          item.description = cardData.item.description;
          item.itemNumber = cardData.item.itemNumber;
          item.location = cardData.item.location;
          item.lot = cardData.item.lot;
          this.pointedItemsDataSource._updateChangeSubscription();
        }, 100);
      }
    });
  }

  addNotPointedItem() {
    const cardData = new CycleCountCanisterNotPointedItemDialogData(new CycleCountCanisterNotPointedItem());
    cardData.item.cycleNumber = this.data.id;
    cardData.item.location = this.data.location.substring(0, this.data.location.length - 1);
    cardData.item.locationSuffix = this.data.location.substring(this.data.location.length - 1, this.data.location.length);
    const dialogRef = this.dialog.open(CycleCountCanisterNotPointedItemComponent, {
      data: cardData,
      autoFocus: false,
      width: '80vw'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadData();
      }
    });
  }

  editNotPointedItem(item: CycleCountCanisterNotPointedItem) {
    const cardData = new CycleCountCanisterNotPointedItemDialogData(Object.assign({}, item));
    cardData.item.location = this.data.location.substring(0, this.data.location.length - 1);
    cardData.item.locationSuffix = this.data.location.substring(this.data.location.length - 1, this.data.location.length);
    const dialogRef = this.dialog.open(CycleCountCanisterNotPointedItemComponent, {
      data: cardData,
      width: '80vw'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        setTimeout(() => {
          item.bull.id = cardData.item.bull.id;
          item.bull.code = cardData.item.bull.code;
          item.bull.name = cardData.item.bull.name;
          item.location = cardData.item.location;
          item.lot = cardData.item.lot;
          item.countedQuantity = cardData.item.countedQuantity;
          item.locationSuffix = cardData.item.locationSuffix;
          item.branchPlant = cardData.item.branchPlant;
        }, 100);
        this.loadData();
      }
    });
  }

  deleteNotPointedItem(item: CycleCountCanisterNotPointedItem) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: `<p>${this.translateService.instant('messages.confirmDelete')}</p>` }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._dataService.deleteCanisterNotPointedItem(item.id).subscribe(() => {
          setTimeout(() => {
            this.notPointedItemsDataSource.data.splice(
              this.notPointedItemsDataSource.data.findIndex((i) => i.id == item.id), 1);
            this.notPointedItemsDataSource._updateChangeSubscription();
          }, 100);
        });
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}
