<form #form="ngForm" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>

        <div class="d-flex align-items-center">
            <mat-form-field class="flex-grow-1 mr-3" [hideRequiredMarker]="true">
                <mat-label>{{'location' | translate}}</mat-label>
                <input name="txtLocation" matInput disabled readonly required [(ngModel)]="data.item.location">
            </mat-form-field>
            <!-- <button type="button" mat-flat-button color="primary" (click)="openLocationSearchDialog()" class="search">
                <mat-icon>search</mat-icon>
            </button> -->
        </div>

        <!-- <div [ngClass]="{'mat-form-field-invalid':form.submitted && !data.item.locationSuffix}">
            <mat-radio-group [(ngModel)]="data.item.locationSuffix" name="rbSuffix" class="d-block mb-3 flex-grow-1"
                required>
                <mat-radio-button value="X" class="mr-4">X</mat-radio-button>
                <mat-radio-button value="Y">Y</mat-radio-button>
            </mat-radio-group>
        </div> -->

        <div class="d-flex align-items-center">
            <mat-form-field class="flex-grow-1 mr-3" [hideRequiredMarker]="true">
                <mat-label>{{'bullCode' | translate}}</mat-label>
                <input name="txtItemNumber" matInput readonly required [(ngModel)]="data.item.bull.code">
                <mat-error>
                    {{'messages.requiredField' | translate}}
                </mat-error>
            </mat-form-field>
            <button type="button" mat-flat-button color="primary" (click)="openBullSearchDialog()" class="search">
                <mat-icon>search</mat-icon>
            </button>
        </div>

        <mat-form-field class="w-100" [hideRequiredMarker]="true">
            <mat-label>{{'bullName' | translate}}</mat-label>
            <input name="txtDescription" matInput disabled [(ngModel)]="data.item.bull.name">
        </mat-form-field>

        <mat-form-field class="w-100" [hideRequiredMarker]="true">
            <mat-label>{{'batch' | translate}}</mat-label>
            <input name="txtLot" matInput required [(ngModel)]="data.item.lot" #fcLot>
            <mat-error>
                {{'messages.requiredField' | translate}}
            </mat-error>
        </mat-form-field>

        <div class="d-flex align-items-center">
            <mat-form-field class="flex-grow-1 mr-3">
                <mat-label>{{'branchPlant' | translate}}</mat-label>
                <input name="txtBranchPlant" matInput disabled readonly [(ngModel)]="data.item.branchPlant">
            </mat-form-field>           
        </div>

        <mat-form-field class="w-100" [hideRequiredMarker]="true">
            <mat-label>{{'quantity' | translate}}</mat-label>
            <input name="txtQuantity" type="number" min="0" matInput required [(ngModel)]="quantity"
                #fcQuantity>
            <mat-error>
                {{quantity < 0 ? translateService.instant('messages.minValue') : translateService.instant('messages.requiredField')}}
            </mat-error>
        </mat-form-field>

        <small *ngIf="error" class="text-danger d-block">{{'messages.operationError' | translate}}</small>
        <small *ngIf="branchError" class="text-danger d-block">{{'messages.branchPlantNotFound'}}</small>
    </div>

    <div mat-dialog-actions class="justify-content-end m-0">
        <button mat-flat-button type="button" (click)="cancel()" class="mr-4" color="warn">{{'buttons.cancel' | translate}}</button>
        <button mat-flat-button type="submit" color="primary">{{'buttons.ok' | translate}}</button>
    </div>

</form>

<app-loader></app-loader>