import { DataService } from './../../services/data.service';
import { ConfirmDialogComponent } from './../../confirm-dialog/confirm-dialog.component';
import { LoaderService } from './../../services/loader.service';
import { CycleCountListItem } from 'src/app/models/cycle-count-list-item.model';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { CycleCountPointedItem } from 'src/app/models/cycle-count-pointed-item.model';
import { CycleCountNotPointedItem } from 'src/app/models/cycle-count-not-pointed-item.model';
import { CycleCountPointedItemComponent } from '../cycle-count-pointed-item/cycle-count-pointed-item.component';
import { CycleCountNotPointedItemComponent } from '../cycle-count-not-pointed-item/cycle-count-not-pointed-item.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NotPointedItemDialogData } from 'src/app/models/not-pointed-item-dialog-data';
import { PointedItemDialogData } from 'src/app/models/pointed-item-dialog-data';
import { Status } from 'src/app/utils/constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cycle-count',
  templateUrl: './cycle-count-items.component.html',
  styleUrls: ['./cycle-count-items.component.scss']
})
export class CycleCountItemsComponent implements OnInit, AfterViewInit {

  pointedItemsDataSource: MatTableDataSource<CycleCountPointedItem>;
  pointedItemsColumns: string[] = ['quantity', 'itemNumber', 'description', 'location', 'lot'];
  notPointedItemsDataSource: MatTableDataSource<CycleCountNotPointedItem>;
  notPointedItemsColumns: string[] = ['quantity', 'itemNumber', 'description', 'location', 'lot'];
  archivedOrSent: boolean;

  constructor(
    private _dataService: DataService,
    public translateService: TranslateService,
    public loaderService: LoaderService,
    public dialogRef: MatDialogRef<CycleCountItemsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CycleCountListItem,
    public dialog: MatDialog) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.archivedOrSent = this.data.sent || (this.data.statusId !== Status.Generated && this.data.statusId !== Status.Inserted);
    if (this.archivedOrSent) {
      this.pointedItemsColumns = ['quantityArchivedOrSent', 'quantityRecountArchivedOrSent', 'itemNumber', 'description', 'location', 'lot'];
      this.notPointedItemsColumns = ['quantityArchivedOrSent', 'quantityRecountArchivedOrSent', 'itemNumber', 'description', 'location', 'lot'];
    } else if (this.data.isRecount) {
      this.pointedItemsColumns = ['quantityRecount', 'itemNumber', 'description', 'location', 'lot'];
      this.notPointedItemsColumns = ['quantityRecount', 'itemNumber', 'description', 'location', 'lot'];
    }
  }

  ngAfterViewInit() {
    if (this.data) {
      setTimeout(() => {
        this.loadData();
      }, 100);
    }
  }

  loadData() {
    this._dataService.getItems(this.data.id).subscribe((response) => {
      if (response) {
        this.pointedItemsDataSource = new MatTableDataSource(response.pointedItems);
        this.notPointedItemsDataSource = new MatTableDataSource(response.notPointedItems);
      }
    });
  }

  editPointedItem(item: CycleCountPointedItem) {
    const cardData = new PointedItemDialogData(this.data.isRecount, Object.assign({}, item));
    const dialogRef = this.dialog.open(CycleCountPointedItemComponent, {
      data: cardData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        setTimeout(() => {
          // item.countQuantity = cardData.item.countQuantity;
          // item.recountQuantity = cardData.item.recountQuantity;
          // item.warn = cardData.item.warn;
          // item.countImageUrl = cardData.item.countImageUrl;
          // item.recountImageUrl = cardData.item.recountImageUrl;
          // item.imageBase64 = cardData.item.imageBase64;
          this.pointedItemsDataSource._updateChangeSubscription();
        }, 100);
      }
    });
  }

  addNotPointeItem() {
    const cardData = new NotPointedItemDialogData(this.data.isRecount, new CycleCountNotPointedItem());
    cardData.item.cycleNumber = this.data.id;
    const dialogRef = this.dialog.open(CycleCountNotPointedItemComponent, {
      data: cardData,
      autoFocus: false,
      width: '80vw'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // setTimeout(() => {
        //   this.notPointedItemsDataSource.data.push(cardData.item);
        //   this.notPointedItemsDataSource._updateChangeSubscription();
        // }, 100);
        this.loadData();
      }
    });
  }

  editNotPointedItem(item: CycleCountNotPointedItem) {
    const cardData = new NotPointedItemDialogData(this.data.isRecount, Object.assign({}, item));
    const dialogRef = this.dialog.open(CycleCountNotPointedItemComponent, {
      data: cardData,
      width: '80vw'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // setTimeout(() => {
        //   item.bull.id = cardData.item.bull.id;
        //   item.bull.code = cardData.item.bull.code;
        //   item.bull.name = cardData.item.bull.name;
        //   item.location = cardData.item.location;
        //   item.lot = cardData.item.lot;
        //   item.countQuantity = cardData.item.countQuantity;
        //   item.recountQuantity = cardData.item.recountQuantity;
        //   item.locationSuffix = cardData.item.locationSuffix;
        //   item.branchPlant = cardData.item.branchPlant;
        //   item.countImageUrl = cardData.item.countImageUrl;
        //   item.recountImageUrl = cardData.item.recountImageUrl;
        // }, 100);
        this.loadData();
      }
    });
  }

  deleteNotPointedItem(item: CycleCountNotPointedItem) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: `<p>${this.translateService.instant('messages.confirmDelete')}</p>` }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._dataService.deleteNotPointedItem(item.id).subscribe(() => {
          setTimeout(() => {
            this.notPointedItemsDataSource.data.splice(
              this.notPointedItemsDataSource.data.findIndex((i) => i.id == item.id), 1);
            this.notPointedItemsDataSource._updateChangeSubscription();
          }, 100);
        });
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }

}
